import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import '../../static/css/tilesets.css';



function Minigame({game, type}) {


	const { t } = useTranslation();

	var detectedLanguage = window.location.pathname.split('/')[1];
    if(detectedLanguage === "" || detectedLanguage.length > 2) {
    	detectedLanguage = "en";
    }


    function textToUpper({game}) {
        if(game === "same") {
            return "SAME GAME";
        }if(game === "spellbee") {
            return "SPELL BEE";
        }if(game === "guesser") {
            return "NUMBER GUESSER";
        }
        return game.toUpperCase();
	}

	function getUrl(type, detectedLanguage, game) {
		if(type === "out") {
			var href = {
				"spellbee": "https://www.spell-bee.com/index.html?utm_source=Tiles",
				"guesser": "https://www.number-guesser.com/index.html?utm_source=Tiles",
				"ladder": "https://www.word-ladder.com/index.html?utm_source=Tiles",
				"chain": "https://www.word-chain.co/index.html?utm_source=Tiles",
				"strands": "https://www.strands-wordle.com/?utm_source=Tiles",
				"wordle": "https://www.infinite-wordle.com/?utm_source=Tiles",
				"word search": "https://www.unlimited-tiles.com/wordsearch"
			}
			return href[game];
		}
		return "/" + detectedLanguage + "/" + game + "/tilesets";
	}

	function getAlt({game}) {
		var alts = {
			"tiles": "tiles game",
			"same": "same game",
			"lines": "color lines game",
			"pairs": "match pairs game",
			"infinite": "infinite tiles game",
			"spellbee": "spell bee game",
			"strands": "strands wordle game",
			"wordle": "infinite wordle game",
			"guesser": "number guesser game",
		};
		return alts[game];
	}

    return (
        <Col className='tilesetContainer'>
            <Card className='tilesetCard tilesetCardSmaller' >
                <a href={getUrl(type, detectedLanguage, game)}>
                 {
                    type === "in" ? <Card.Img alt={getAlt({game})} variant="top" src={"/images/games/" + game + "/default.png"}  />
                    : <Card.Img alt={getAlt({game})} variant="top" src={"/images/moregames/" + game + ".png"}  />
                 }

                <Card.Body>
                    <Card.Title>{textToUpper({game})}</Card.Title>
                    <Button href={getUrl(type, detectedLanguage, game)} className="small-btn" variant="primary" >PLAY</Button>

                </Card.Body>
                </a>
            </Card>
        </Col>
    );
}




const Minigames = ({random = false, game = "tiles"}) => {
	const { t } = useTranslation();
	var games = [ "tiles", "pairs", "unpaired", "timed", "infinite", "lines", "same"];

	if(random) {

		const shuffled = games.filter(function(e) { return e !== game}).sort(() => 0.5 - Math.random());
        games = shuffled.slice(0, 2);

	}

	const gamesHtml =  (

		<Container>
	        {
	            random
	            ? <><br/><h2>You can also play one of our other games</h2>
	            </>
	            :  <><h2>{ t("otherGames") }</h2>
	            <p className="intro"><a href="/ourgames"><b>Click here </b> to read more about our tiles games. </a></p>
	            </>
	        }
	        {
	            <Row>
	                {games.map(game => ( <Minigame game={game} key={game} type="in"/>))}

	            </Row>
	        }
	    </Container>
    );

    return gamesHtml;
}
export default Minigames;
