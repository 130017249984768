import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';

import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';
import general from '../static/json/general.json';
import levels_lines from '../static/json/levels_lines.json';


function Tileset({tileset, date}) {

	//var tilesetImage = require();
	const navigate = useNavigate();

	function bestScore() {
		const best = localStorage.getItem("tiles-" + tileset + "-best");
		if(best != undefined) {
			return (<>Best so far: {best}</>)
		} else {
			return (<> - </>)
		}
	}

	function playGame({game, tileset}) {
    	if(game == "tiles") {
    	    navigate("/en/" + tileset);
    	} else {
    	    navigate("/en/" + game + "/" + tileset);
    	}
    }

	function textToUpper({tileset}) {
		const titles = {
        	"rubik": "RUBIK'S CUBE HARD",
        	"rubik2": "RUBIK'S CUBE",
        	"rubik3": "RUBIK'S CUBE EASY",
        	"bwcircles": "B&W CIRCLES"
        };
        if(tileset in titles) {
        	return titles[tileset];
        } else {
			return tileset.toUpperCase()
		}
	}

	const todayDate = new Date();
    const dateDate = new Date(date);
    if(dateDate < todayDate) {
        return (<></>);
    }

    return (
        <>
        <Col>
            <Card className='tilesetCard'>
                  <Card.Img variant="top" src={"/images/games/tiles/" + tileset + ".png"}  />
                  <Card.Body>
                    <Card.Title>{textToUpper({tileset})}</Card.Title>
                    <Card.Title>{date}</Card.Title>
                    <Card.Text>{bestScore()}</Card.Text>
                    <Button className="small-btn" variant="primary" href={"/en/tiles/" + tileset}>Play</Button>
                  </Card.Body>
                </Card>
        </Col>
        <Col>
            <Card className='tilesetCard'>
                  <Card.Img variant="top" src={"/images/games/pairs/" + tileset + ".png"}  />
                  <Card.Body>
                    <Card.Title>PAIRS</Card.Title>
                    <Card.Title>{date}</Card.Title>
                    <Card.Text>{bestScore()}</Card.Text>
                    <Button className="small-btn" variant="primary" href={"/en/pairs/" + tileset}>Play</Button>
                  </Card.Body>
                </Card>
        </Col>
        <Col>
            <Card className='tilesetCard'>
                  <Card.Img variant="top" src={"/images/games/infinite/" + tileset + ".png"}  />
                  <Card.Body>
                    <Card.Title>INFINITE</Card.Title>
                    <Card.Title>{date}</Card.Title>
                    <Card.Text>{bestScore()}</Card.Text>
                    <Button className="small-btn" variant="primary" href={"/en/infinite/" + tileset}>Play</Button>
                  </Card.Body>
                </Card>
        </Col>

        </>
    );
}



function TilesetLines({tileset, date}) {

	//var tilesetImage = require();
	const navigate = useNavigate();

	function bestScore() {
		const best = localStorage.getItem("lines-" + tileset + "-best");
		if(best != undefined) {
			return (<>Best so far: {best}</>)
		} else {
			return (<> - </>)
		}
	}

	function playGame({game, tileset}) {
    	if(game == "tiles") {
    	    navigate("/en/" + tileset);
    	} else {
    	    navigate("/en/" + game + "/" + tileset);
    	}
    }

	function textToUpper({tileset}) {
		return tileset.toUpperCase()

	}

	function getStars(tileset) {

		console.log(tileset);
    	const best = localStorage.getItem("lines" + "-" + tileset + "-best");

    	var possible = levels_lines[tileset]["best"];
    	var starsAmount = 0;

    	if(best != undefined) {
    	    starsAmount = 1;
    		if(best <= possible) {
    		    starsAmount = 3;
    		} else if (best <= possible + 3) {
    			starsAmount = 2;
    		}
    	}
		console.log(starsAmount);
    	return starsAmount;
    }

    function showStars(stars) {
		return (
			<div>
				{(stars >= 1) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}
				{(stars >= 2) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}
				{(stars >= 3) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}

			</div>
		);

	}

	const todayDate = new Date();
    const dateDate = new Date(date);
    if(dateDate < todayDate) {
        return (<></>);
    }

    return (
        <>
        <Col>
            <Card className='tilesetCard'>
                  <Card.Img className="squareTilesetImage"  variant="top" src={"/images/games/lines/" + tileset + ".png"}  />
                  <Card.Body>
                    <Card.Title>{textToUpper({tileset})}</Card.Title>
                    <Card.Title style={{"marginTop": "-10px"}}>{date}</Card.Title>
                    { showStars(getStars(tileset)) }
                    <Card.Text style={{"marginTop": "-5px"}}>{bestScore()}</Card.Text>
                    <Button className="small-btn" variant="primary" href={"/en/lines/" + tileset}>Play</Button>
                  </Card.Body>
                </Card>
        </Col>

        </>
    );
}


const Tilesets = () => {
	const { t } = useTranslation();
	const tilesets = general["waiting"];
	const tilesets3 = general["lines"];

	const tilesetHtml =  (
        <div className="mainPart">
        <Helmet>
            <title>Unlimited Tiles - Available Tilesets</title>
            <meta name="description" content="Choose your favorite tileset and play unlimited tiles" />
            <link rel="canonical" href="https://www.unlimited-tiles.com/zestawy" />
        </Helmet>
        <Container>
          <h1> Choose your favorite tileset to play</h1>
            {tilesets.map(tileset => ( <Row><Tileset tileset={tileset["name"]} date={tileset["date"]} key={tileset["name"]}  /></Row>)) }
            <h1>Lines</h1>
            <Row>{tilesets3.map(tileset => ( <TilesetLines tileset={tileset["name"]} date={tileset["date"]} key={tileset["name"]}  />)) } </Row>

        </Container>
        </div>
      );

    return tilesetHtml
}
export default Tilesets;

