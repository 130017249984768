import general from '../../static/json/general.json';
import levels_lines from '../../static/json/levels_lines.json';


function getLevels(game) {
	if(game == "same") {
		return general[game];
	} else if(game == "lines") {
		return general[game].map(tset => (tset["name"]));
	} else {
		return general["waiting"].map(tset => (tset["name"]));
	}
}

function getAllLevels({allgames}) {
	var levels = {};
	for(var i = 0; i < allgames.length; i++) {
		var game = allgames[i];
		levels[game] = getLevels(game);
	}
	return levels;
}

function getStars({tileset, game}) {
	const fourLayersSets = general["fourlayers"];
	var best = localStorage.getItem(game + "-" + tileset + "-best");

	var possible = 0;
	if(game == "lines") { possible = levels_lines[tileset]["best"];}
	else if(game == "tiles") {
		if(fourLayersSets.includes(tileset)) {
			possible = 60;
		} else {
			possible = 45;
		}
	}
	else if(game == "pairs") { possible = 24; }
	else if(game == "unpaired") { possible = 30; }
	else if(game == "infinite") { possible = 45; }
	else if(game == "timed") { possible = 45; }
	else if(game == "same") {
		const bestInSame = {
                "two": 5,
            	"three": 10,
            	"four": 21,
            	"five": 30,
            }
		possible = bestInSame[tileset];
	}

	var starsAmount = 0;
	if(best != undefined) {
		starsAmount = 1;

		if(game == "same" || game == "lines") {
			if(best <= possible) {
				starsAmount = 3;
			} else if (best <= possible + 3) {
				starsAmount = 2;
			}
		} else if(game == "unpaired") {
			if(best <= possible) {
				starsAmount = 3;
			} else if (best <= possible + 30) {
				starsAmount = 2;
			}
		} else {
			if(best >= possible) {
				starsAmount = 3;
			} else if (best >= possible - 5) {
				starsAmount = 2;
			}
		}
	}
	localStorage.setItem(game + "-" + tileset + "-stars", starsAmount);
}

function countAllStars({allgames, levels}) {
	for(var i = 0; i < allgames.length; i++) {
		var game = allgames[i];
		var gameLevels = levels[game];
		for(var j = 0; j < gameLevels.length; j++) {
			var tileset = gameLevels[j];
			getStars({tileset, game});
		}
	}
}


export function countStars() {

	var allgames = ["tiles", "pairs", "infinite", "timed", "unpaired", "same", "lines"];
	var levels = getAllLevels({allgames});

	countAllStars({allgames, levels});
}