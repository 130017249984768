import React from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../static/css/shares.css';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
import { RedditShareButton, RedditIcon } from 'react-share';


import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');

export default function Shares({game, tileset, score, image="gt"}) {

	const { t } = useTranslation();

	var text = t("whatsappText");
	var title = {
		"same": "Same Game",
		"infinite": "Infinite Tiles",
		"tiles": "Tiles",
		"pairs": "Pairs",
		"unpaired": "Unpaired",
		"lines": "Lines"
	};
	text = text.replace("GAME", title[game]).replace("SCORE", score);

	var detectedLanguage = window.location.pathname.split('/')[1];
    if(detectedLanguage == "" || detectedLanguage.length > 2) {
    	detectedLanguage = "en";
    }
    var link = window.location.href + "/play";
    const [linkCopied, setLinkCopied] = useState(false);

	function sendEvent(platform) {
		ReactGA.event({
            category: 'Game',
            action: 'Share' + platform
        });
	}

	function copyLink() {
		ReactGA.event({
            category: 'Game',
            action: 'CopyLink'
        });
        setLinkCopied(true);
        console.log(game);
        if(game == "tiles" || game == "tilesbig") {
            var imageColor = "";
            for(var i = 0; i < 6; i++) {
                for(var j = 0; j < 5; j++) {
                    console.log(image[5*i+j]);
                    if(image[5*i+j] == 0) {imageColor += "🟦";}
                    else if(image[5*i+j] == 1) {imageColor += "🟥";}
                    else if(game == "tiles" && image[5*i+j] == 2) {imageColor += "🟨";}
                    else if(game == "tiles" && image[5*i+j] == 3) {imageColor += "🟩";}
                    else if(game == "tilesbig" && image[5*i+j] == 2) {imageColor += "🟨";}
                    else if(game == "tilesbig" && image[5*i+j] == 3) {imageColor += "🟨";}
                    else if(game == "tilesbig" && image[5*i+j] == 4) {imageColor += "🟩";}
                    else {imageColor += "⬛";}
                }
                imageColor += "\n";
            }
            var shareImage = "Game " + title[game] + " - level " + tileset + "\nMy score: " + score + "\n" + imageColor;
            navigator.clipboard.writeText(shareImage + "\n" + link);
        } else {
            navigator.clipboard.writeText(link);
        }

	}

	return (
		<div>
			<br/>
			<h3>{t("shareHeader")}</h3>
			<p>{t("shareButtons")}</p>

			{/* <p>{t("shareText")}</p>
            <input className="link" type="text" defaultValue={link} readonly="readonly"/><br/> */ }
            <Button className="default-btn share-btn" variant="primary" onClick={copyLink}>{t("copyLink")}</Button><br/>
            {linkCopied
                ? <b><p className="linkCopied">{t("linkCopied")}</p></b>
                : <></>
            }
			<div>
		      <FacebookShareButton url={link} title={text} className="btn-share">
		        <FacebookIcon size={50} round={false} borderRadius={14}  onClick={() => sendEvent("Fb")} />
		      </FacebookShareButton>

		      <TwitterShareButton url={link} title={text} className="btn-share" >
		        <TwitterIcon size={50} round={false} borderRadius={14} onClick={() => sendEvent("Twitter")} />
		      </TwitterShareButton>

		      <WhatsappShareButton url={link} title={text} className="btn-share">
		        <WhatsappIcon size={50} round={false} borderRadius={14} onClick={() => sendEvent("Whatsapp")} />
		      </WhatsappShareButton>

		      <RedditShareButton url={link} title={text} className="btn-share">
		        <RedditIcon size={50} round={false} borderRadius={14}  onClick={() => sendEvent("Reddit")} />
		      </RedditShareButton>
		    </div>
            <br/>


	    </div>
    );
}