import logo from './../../logo.svg';
import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import Instruction from './../Instruction';
import general from './../../static/json/general.json';
import './../../static/css/neighbours.css';

import 'animate.css';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');



function Square({value, squareClass, onSquareClick, tileset}) {
	var tiles = "";

	function getImage() {
		if(value > 0) {
			return <div className="image"><img src={"/images/games/neighbours/d" + value + ".png"} alt="Tile"/></div>;
		} else if(value < 0) {
			return <div className="image animate__animated animate__backOutLeft"><img src={"/images/games/neighbours/d" + (-1)*value + ".png"} alt="Tile"/></div>;
		} else {
			return "";
		}
	}

	function getBackground() {
		return <div className="image"><img src={"/images/tilesets/background.png"} alt="X"/></div>;
	}

    return (
        <td className={"neighboursSquare " + squareClass}  onClick={onSquareClick}>
            { getImage() }

        </td>
    );
}

function getRandomStart(tileset) {
	const board = [];
	const R = 8, C = 8;
	const colors = {
		"two": 4,
	    "three": 6,
	    "four": 4,
	    "five": 5,
	    "six": 6,
	    "seven": 7,
	    "eight": 8,
	}
	var values = [11,12,13,21,22,23];

    for (let i = 0; i < C; i++) {
        board[i] = [];
        for (let j = 0; j < R; j++) {
            var ran = values[Math.floor(Math.random()*colors[tileset])];
            board[i][j] = ran;
        }
    }
	return board;
}

function getEmptyBoard() {
	const board = [];
	const R = 8, C = 8;
    for (let i = 0; i < C; i++) {
        board[i] = [];
        for (let j = 0; j < R; j++) {
            board[i][j] = 0;
        }
    }
	return board;
}

export default function Tiles({onTilesFinish, tileset}) {
    const [squares, setSquares] = useState(getRandomStart(tileset));

    const [currentCombo, setCurrentCombo] =  useState(0);
    const [points, setPoints] =  useState(0);
    const [longestCombo, setLongestCombo] =  useState(0);
    const [squaresLeft, setSquaresLeft] = useState(300);
    const [moves, setMoves] = useState(0);
    const [moveMessage, setMoveMessage] = useState("");

    const [columnPrev, setColumnPrev] = useState(-1);
    const [rowPrev, setRowPrev] = useState(-1);
    const [clickedSquares, setClickedSquares] = useState(getEmptyBoard());


	function cleanUpArea() {

		var nextSquares = squares.slice();
		for(var i = 0; i < C; i++) {
			for(var j = 0; j < R; j++) {
				clickedSquares[i][j] = 0;
				while(nextSquares[i][j] < 0) {
					nextSquares[i].splice(j,1);
				}
			}
			while(nextSquares[i].length < R) {
                nextSquares[i].push(0);
            }
		}
		setClickedSquares(clickedSquares);

		for(var i = 0; i < C; i++) {
			while(i <= nextSquares.length - 1 && nextSquares[i][0] == 0) {
				nextSquares.splice(i,1);
			}
			while(nextSquares.length < C) {
				nextSquares.push([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]);
			}
		}
		setSquares(nextSquares);

		var leftSquares = 0;
		for(var i = 0; i < C; i++) {
			for(var j = 0; j < R; j++) {
				if(squares[i][j] > 0) {
					leftSquares += 1;
				}
			}
		}


		return leftSquares;
	}

	function resetCombo() {

		for (let i = 0; i < C; i++) {
	        for (let j = 0; j < R; j++) {
	            if(clickedSquares[i][j]) {
	                squares[i][j] = (-1)*squares[i][j];
	            }
	        }
	    }
	    setColumnPrev(-1);
        setRowPrev(-1);
        var resultPoints = points + currentCombo*currentCombo;
        setPoints(points + currentCombo*currentCombo);
        setCurrentCombo(0);
	    setSquares(squares);

	    const timeout = setTimeout(() => {
	        var left = cleanUpArea();
	        setSquaresLeft(left);

	        if(left == 0) {
	            onTilesFinish(resultPoints);
	        }

	    }, 600);

	}

	function checkPair(col1, row1, col2, row2) {

		var color1 = squares[col1][row1] % 10;
		var shape1 = (squares[col1][row1] - color1)/10;
		var color2 = squares[col2][row2] % 10;
		var shape2 = (squares[col2][row2] - color2)/10;

		if(Math.abs(col1-col2) + Math.abs(row1-row2) != 1) {
			setMoveMessage("Tiles aren't neighbours");
			resetCombo();
			return;
		}

		if(squares[col1][row1] == squares[col2][row2]) {
			setMoveMessage("Tiles are identical");
			resetCombo();
            return;
		}

		if(color1 != color2 && shape1 != shape2) {
			setMoveMessage("Tiles aren't similar");
			resetCombo();
            return;
		}

		clickedSquares[col2][row2] = 1;
		setClickedSquares(clickedSquares);

        setLongestCombo(Math.max(longestCombo, currentCombo+1));
        setCurrentCombo(currentCombo+1);

		setColumnPrev(col2);
        setRowPrev(row2);

	}


	function handleClick(column, row) {
		if (squares[column][row] == 0 || squares[column][row] == undefined ) {
            return;
        }

        if(clickedSquares[column][row] == 1 || (column == columnPrev && row == rowPrev)) {
            resetCombo();
            return;
        }

        if(columnPrev == -1) {
            setCurrentCombo(1);
            setLongestCombo(Math.max(longestCombo,1));
            setColumnPrev(column);
            setRowPrev(row);

            clickedSquares[column][row] = 1;
            setClickedSquares(clickedSquares);
            setMoveMessage("");

        } else {
			checkPair(columnPrev, rowPrev, column, row);
			setMoves(moves+1);
        }



    }

	const R = 8;
	const C = 8;
	var rows = Array(R);
	var columns = Array(C);
	for(var i = 0; i < R; i++) {
		rows[i] = i;
	}
	for(var i = 0; i < C; i++) {
		columns[i] = i;
	}

	function lineCode(num) {
		var row = R - num - 1;
		return (
			<tr className="board-row">{
				columns.map(column => (
					<Square tileset={tileset} key={C*num+column} value={squares[column][row]}
                        squareClass={ clickedSquares[column][row] == 1 ? 'redSquare' : 'whiteSquare' }
						onSquareClick={() => handleClick(column,row)} />

				))}
			</tr>
		)
	};


	const { t } = useTranslation();
	function getInstruction() {
	    return t("moveInfoSameTiles");
	}

	const boardCode = (<div className="fullBoard">

			<Row>
				<Row className="instruction">
                    <div className="col2"><p className="instructionText"> {getInstruction()} </p></div>
                </Row>
				<div style={{"height": "30px"}}>
					<div style={{"background-color": "red", "color": "white", "max-width": "360px", "margin": "auto",
					"margin-bottom": "10px", "border-radius": "3px"}}>
					{ moveMessage }
					</div>
				</div>

				<Col sm={9} className="gameBoardCol">
					<Table className="neighboursGameBoard"><tbody>
					{rows.map(row => lineCode(row))}
					</tbody></Table>
				</Col>
				<Col sm={3}>
					<Row className="gameStats">
					<div className="col1">{ "COMBO" }<br/><h1>{ currentCombo + " (+" + currentCombo*currentCombo + ")" }</h1></div>
					<div className="col1">{ "POINTS" }<br/><h1>{ points }</h1></div>
					<div className="col1">{ }<br/><h1>{  }</h1></div>
					{ /* <div className="col1">{ t("LEFT") }<br/><h1>{ squaresLeft }</h1></div>*/ }
					<br/>
					<Button variant="success" className="small-btn" onClick={() =>  window.location.reload()}>RESTART</Button>
					</Row>
				</Col>
			</Row>

	    </div>
	);

    return boardCode;
}

