import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';


import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');



function goToAmazon(source) {
	console.log(source);
	ReactGA.event({
        category: 'Amazon',
    	action: 'GoToAmazon',
    	value: source
    });
	//window.location.href = "https://www.amazon.com/dp/B0DFWN5KN7/";
}


const WordSearch = () => {

	const tilesetHtml =  (
        <div className="mainPart">
        <Helmet>
            <title>Twisted Word Search</title>
            <meta name="description" content="Twisted Word Search - modern word search with twisted words" />
            <link rel="canonical" href="https://www.unlimited-tiles.com/wordsearch" />
            <meta property="og:url" content="https://www.unlimited-tiles.com/wordsearch" />
            <meta property="og:image" content="https://www.unlimited-tiles.com/banner.png" />
            <meta property="og:title" content="Twisted Word Search - modern word search with twisted words"/>
            <meta property="og:description" content="Find hidden words "/>
        </Helmet>

        <Container>
            <h1>Our Word Search Book</h1>
			<p>We're excited to share our new <b>Twisted Word Search</b> book with you! It's packed with 200 puzzles and over
			2,300 words to find, but there's a fun twist—literally! In this book, the words are hidden in unexpected ways,
			making each puzzle a bit more challenging and a lot more fun.</p>

            <p>As you find the words, the leftover letters reveal a hidden message, adding a little extra surprise at the end of each puzzle.</p>

			<a href="https://www.amazon.com/dp/B0DFWN5KN7/" >
				<img className="bigImg" alt="word search book" onClick={()=>goToAmazon("image2")} src="/images/wordsearch/2.png" />
			</a>

            <p>Perfect for all ages, this book is a great way to relax, challenge your brain, and enjoy some quiet time.
               Grab your copy today and discover a new spin on classic word search puzzles!</p>



			<a href="https://www.amazon.com/dp/B0DFWN5KN7/" >
				<img className="bigImg" alt="word search game" src="/images/wordsearch/1.png" />
			</a>

			<p>Our <b>Twisted Word Search</b> book is now available at a promotional price of $6.99 on Amazon.</p>
			<Button href="https://www.amazon.com/dp/B0DFWN5KN7/" style={{"margin": "auto"}} variant="success" className="default-btn" onClick={() => goToAmazon("button")}>Go to Amazon.com</Button>
			<br/>
			<br/>
			<br/>


			<h1>Twisted Word Search - SPICES</h1>
			<p>If you're not sure if our twisted word search is for you, you can watch our video on YouTube.</p>
			<iframe width="320" height="180" src="https://www.youtube.com/embed/TYily5V9SXM?si=F1c7EdvcD_zwttpq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>


        </Container>
        </div>
      );

    return tilesetHtml
}

export default WordSearch;

