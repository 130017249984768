import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet';

import Button from 'react-bootstrap/Button';


function Test() {
	const { t } = useTranslation();

    return (
        <div className="mainPart">
        <Helmet>
        	            <title>Unlimited Tiles - How to play?</title>
        	            <meta name="description" content="Choose your favorite tileset and play unlimited tiles" />
        	            <link rel="canonical" href={"https://www.unlimited-tiles.com/howtoplay"} />
        	            <meta property="og:url" content={"https://www.unlimited-tiles.com/howtoplay"} />
        	            <meta property="og:image" content="https://www.unlimited-tiles.com/banner2.png" />
        	            <meta property="og:title" content="Unlimited Tiles - How to play?" />
        	        </Helmet>
        <Container>
            <Row>

                <h1>How to play Tiles?</h1>
    	        <p className="intro">Beginner's guide to Tiles game</p>

				<div className="paragraph">

					<h3>1. Basic rules</h3>
					<p>The Tiles game board consists of 30 square tiles arranged in 5 columns and 6 rows. Each tile is made up of
						3 layers stacked on top of each other. Your goal is to find pairs of tiles that share at least one common layer.
						When you find a matching pair, the shared layer disappears, and the remaining layers stay in place.</p>

					<h3>2. What is the goal of Tiles?</h3>
					<p>The objective of the game is to remove all layers from all tiles and score as many points as possible.
                       For each pair of matching tiles, you earn 1 point, regardless of whether they share 1, 2, or 3 layers.
                       If you make a mistake, your score resets to zero.</p>

					<h3>3. Highest possible score in Tiles</h3>
					<p>The highest possible score is 45. There are 30 tiles in total, each with 3 layers. This gives us 90 layers,
                       which means there are 45 possible pairs. <b>To achieve the maximum score, you must avoid making mistakes
                       (selecting pairs that do not share a common layer) and avoid removing more than one pair of layers at a time. </b></p>

					<p>
					Mastering the Tiles puzzle takes a combination of observation, strategy, and a touch of patience.
					As you explore the details of this interesting puzzle, have fun with the challenge and feel good when you clear the tiles.
					Happy matching!
					</p>

					<Button variant="success" className="default-btn" href="/en/tiles/tilesets">{t('play')}</Button>


				</div>
    	        <br/>
            </Row>

        </Container>
        </div>
    );
}
export default Test;