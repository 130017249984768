import logo from './../../logo.svg';
import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import Instruction from './../Instruction';
import general from './../../static/json/general.json';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import 'animate.css';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');


function getResult(game, tileset, param) {
	return localStorage.getItem(game + "-" + tileset + "-" + param);
}

function setResult(game, tileset, param, result) {
	return localStorage.setItem(game + "-" + tileset + "-" + param, result);
}

function Square({value, isCurrent, onSquareClick, tileset}) {
	var classes = isCurrent ? "square currentSquare" : "square";
	var tiles = "";

	function getImage(num) {
		var type = ["a", "b", "c"];
		if(value[num] > 0) {
			return <div className="image"><img src={"/images/tilesets/" + tileset + "/" + type[num] + value[num] + ".png"} alt="Tile"/></div>;
		} else {
			return <div className="image animate__animated animate__backOutLeft">
			<img src={"/images/tilesets/" + tileset + "/" + type[num] + (-1)*value[num] + ".png"} alt="Tile"/></div>;
		}
	}

	function getBackground() {
		return <div className="image"><img src={"/images/tilesets/" + tileset + "/background.png"} alt="X"/></div>;

	}

    return (
        <td className={classes} onClick={onSquareClick}>
			{ getBackground() }
            { getImage(0) }
            { getImage(1) }
            { getImage(2) }

        </td>
    );
}

function getRandomStart() {

	var board = Array(30).fill([0,0,0]);
	var layer1 = [1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3];
    var layer2 = [1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3];
    var layer3 = [1,1,1,1,1,1,2,2,2,2,2,2,3,3,3,3,3,3,4,4,4,4,4,4,5,5,5,5,5,5];

	layer1.sort(() => Math.random() - 0.5);
    layer2.sort(() => Math.random() - 0.5);
    layer3.sort(() => Math.random() - 0.5);
	layer1.sort(() => Math.random() - 0.5);
    layer2.sort(() => Math.random() - 0.5);
    layer3.sort(() => Math.random() - 0.5);
	for(var i = 0; i < 30; i++) {
		board[i] = [layer1[i],layer2[i],layer3[i]];
	}
	return board;
}

export default function Tiles({onTilesFinish, tileset}) {
	const [lastPos, setLastPos] = useState(-1);
    const [squares, setSquares] = useState(getRandomStart);

    const [currentCombo, setCurrentCombo] =  useState(0);
    const [longestCombo, setLongestCombo] = useState(0);
    const [tilesLeft, setTilesLeft] = useState(90);
    const [mistakes, setMistakes] = useState(0);
    const [image, setImage] = useState(Array(30).fill(0));

    const [moveMessage, setMoveMessage] = useState("");
    const [colorMessage, setColorMessage] = useState("#d0d9f0");


	function handleClick(clicked) {

		if (clicked === lastPos || (squares[clicked][0] <= 0 && squares[clicked][1] <= 0 && squares[clicked][2] <= 0)) {
            return;
        }

        const nextSquares = squares.slice();
        if (lastPos === -1) {
            setLastPos(clicked);
        } else {
            var correct = 0;
            var newTilesLeft = tilesLeft;
            var tilesDeleted = 0;
            for(var i = 0; i < 3; i++) {
                if(squares[clicked][i] === squares[lastPos][i] && squares[clicked][i] > 0) {
                    nextSquares[clicked][i] *= (-1);
                    nextSquares[lastPos][i] *= (-1);
                    correct = 1;
                    newTilesLeft -= 2;
                    tilesDeleted += 1;
                }
            }

            if(correct) {
                var newImage = image;
                newImage[clicked] += 1;
                newImage[lastPos] += 1;
                setImage(newImage);
                console.log(newImage);
                if(tilesDeleted == 1) {
                    setMoveMessage("Great! The chosen tiles had 1 common element.");
                    setColorMessage("#51a351");
                } else  {
                    setMoveMessage("The chosen tiles had " + tilesDeleted + " common elements.  ");
                    setColorMessage("orange");
                }

                setTilesLeft(newTilesLeft);
                if(nextSquares[clicked][0] <= 0 && nextSquares[clicked][1] <= 0 && nextSquares[clicked][2] <= 0) {
                    setLastPos(-1);
                } else {
                    setLastPos(clicked);
                }
                var newCurrentCombo = currentCombo + 1;
                var newLongestCombo = longestCombo;
                setCurrentCombo(newCurrentCombo);
                if(newCurrentCombo > longestCombo) {
                    newLongestCombo = newCurrentCombo;
                    setLongestCombo(newLongestCombo);
                }
            } else {
                var newImage = image;
                newImage[lastPos] = 5;
                setImage(newImage);
                console.log(newImage);

                setMistakes(mistakes+1);
                setCurrentCombo(0);
                setMoveMessage("Error: the chosen tiles don't have common elements.");
                setColorMessage("#e70000");
            }
            if(newTilesLeft <= 0) { //Finish game

				if(mistakes == 0) {
					var minimum = getResult("tiles", tileset, "mini");
					if(minimum > newLongestCombo || minimum == null) {
						setResult("tiles", tileset, "mini", newLongestCombo);
					}
				}
				console.log(image.toString(""));
				onTilesFinish(newLongestCombo, image.toString().replaceAll(",", ""));
            }
        }
        setSquares(nextSquares);

    }


	const rows = [0,1,2,3,4,5];
	const columns = [0,1,2,3,4];

	function lineCode(num) {
		return (
			<tr className="board-row">{
				columns.map(column => (
					<Square tileset={tileset} key={5*num+column} value={squares[5*num+column]} isCurrent={lastPos === 5*num+column}
					onSquareClick={() => handleClick(5*num+column)} />
				))}
			</tr>
		)
	};


	const { t } = useTranslation();
	function getInstruction() {
        const instruction = [t("moveInfo1"), t("moveInfo2")];
	    if(lastPos === -1) {
	        return instruction[0];
	    } else {
	        return instruction[1];
	    }
	}

	function getPossibleMax() {
		return currentCombo+tilesLeft/2;
	}
	function getPossibleColor() {
		var possibleScore = getPossibleMax();
		if(possibleScore == 45) {
			return "green";
		} else if(possibleScore >= 40) {
			return "orange";
		} else {
			return "red";
		}
	}

	function getStars() {

		var color = getPossibleColor();
        return (
    		<div className="smallStars">
    			<img className="levelStar" src={"/images/tilesets/lines/star.png"}/>

    			{(color == "green" || color == "orange") ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
    				: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}
    			{(color == "green") ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
    				: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}

    		</div>
    	);

    }


	const boardCode = (<div className="fullBoard">

			<Row>
				<h1 style={{"border":"3px solid black", "margin-bottom": "1px", "margin-top": "5px", "background-color": "#d3dfec"}}>{tileset.toUpperCase()}</h1>
				<Row className="instruction">
                    <div className="col2"><p className="instructionText"> {getInstruction()} </p></div>
                    <div className="col3"> <Instruction/></div>
                </Row>

				<div style={{"background-color": colorMessage, "color": "white", "max-width": "360px", "margin": "auto",
					"margin-bottom": "10px", "border-radius": "3px"}}>
					{ moveMessage }
				</div>
				<Col sm={9} className="gameBoardCol">
					<Table className="gameBoard"><tbody>
					{rows.map(row => lineCode(row))}
					</tbody></Table>
				</Col>
				<Col sm={3}>
					<Row className="gameStats">
					<div className="col1">{ t("currentCombo") }<br/><h1 style={{"font-size": "50px"}}>{ currentCombo }</h1></div>
					<div className="col1">{ t("longestCombo") }<br/><h1 style={{"font-size": "50px"}}>{ longestCombo }</h1></div>
					<div className="col1">{ t("possibleScore") }<br/>
						{ getStars() }
						<h1 style={{"color": getPossibleColor()}}>{ getPossibleMax() }</h1>
					</div>
					<br/>
					<Button variant="success" className="small-btn" onClick={() =>  window.location.reload()}>RESTART</Button>
					</Row>
				</Col>
			</Row>

	    </div>
	);

    return boardCode;
}

