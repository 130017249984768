import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';
import { useState } from 'react';


import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';

import general from '../../static/json/general.json';
import badgesJson from '../../static/json/badges.json';
import '../../static/css/badges.css';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');

var newBadges = {};
var badgesCounter = 0;

function getLevels(game) {
	if(game == "same") {
		return general[game];
	} else if(game == "lines") {
		return general[game].map(tset => (tset["name"]));
	} else {
		return general["waiting"].map(tset => (tset["name"]));
	}
}

function getAllLevels({allgames}) {
	var levels = {};
	for(var i = 0; i < allgames.length; i++) {
		var game = allgames[i];
		levels[game] = getLevels(game);
	}
	return levels;
}

function getStars({levels, game}) {
	var stars = 0;
	for(var i = 0; i < levels[game].length; i++) {
		var lv = levels[game][i];
		var st = localStorage.getItem(game + "-" + lv + "-stars");
		if(st != null) {
			stars += Number(st);
		}
	}
	return stars;
}

function getAllStars({levels, allgames}) {
	var stars = {};
	for(var i = 0; i < allgames.length; i++) {
    	var game = allgames[i];
    	stars[game] = getStars({levels, game});
    }
	return stars;
}

function getTop({levels, game}) {
	var top = 0;
	for(var i = 0; i < levels[game].length; i++) {
		var lv = levels[game][i];
		var st = localStorage.getItem(game + "-" + lv + "-stars");
		if(st == 3) {
			top += 1;
		}
	}
	return top;
}

function getAllTop({levels, allgames}) {
	var stars = {};
	for(var i = 0; i < allgames.length; i++) {
    	var game = allgames[i];
    	stars[game] = getTop({levels, game});
    }
	return stars;
}


function getPlayed({levels, game}) {
	var top = 0;
	for(var i = 0; i < levels[game].length; i++) {
		var lv = levels[game][i];
		var st = localStorage.getItem(game + "-" + lv + "-stars");
		if(st != null && st != 0) {
			top += 1;
		}
	}
	return top;
}

function getAllPlayed({levels, allgames}) {
	var stars = {};
	for(var i = 0; i < allgames.length; i++) {
    	var game = allgames[i];
    	stars[game] = getPlayed({levels, game});
    }
	return stars;
}


/***********************************************************************************************************************/



function BadgesList({game = "all"}) {

	var allgames = [];
	var gameId = 0;
	if(game != "all") {
		var gam = game;
		if(game.includes("all-")) {
			gam = game.replace("all-", "");
		}
		allgames = [gam];
		for(var i = 0; i < badgesJson["badges"].length; i++) {
			if(badgesJson["badges"][i]["name"] == gam) {
				gameId = i;
			}
		}
	} else {
		allgames = ["tiles", "pairs", "infinite", "timed", "unpaired", "same", "lines"];
	}

    var levels = getAllLevels({allgames});
    var stars = getAllStars({levels, allgames});
    var topLevels = getAllTop({levels, allgames});
    var playedLevels = getAllPlayed({levels, allgames});


	const [badgesEarned, setBadgesEarned] = useState(0);

	stars["total"] = 0;
	topLevels["total"] = 0;
	for(var i = 0; i < allgames.length; i++) {
		stars["total"] += stars[allgames[i]];
		topLevels["total"] += topLevels[allgames[i]];
	}

	function checkBadge(conditions) {
		var ok = true;
		for(var i = 0; i < conditions.length; i++) {
			var cond = conditions[i];
			var game = cond[0];

			if(cond[1] == "stars") {
				if(stars[game] < cond[2]) { ok = false; }
			}
			if(cond[1] == "maxed") {
				if(topLevels[game] < cond[2]) { ok = false; }
			}
			if(cond[1] == "finished") {
				if(playedLevels[game] < cond[2]) { ok = false; }
			}
			if(cond[1] == "level") {
				var levelStars = localStorage.getItem(game + "-" + cond[2] + "-stars");
	            if(levelStars == null || levelStars == 0) { ok = false; }
			}
			if(cond[1] == "maxlevel") {
				var levelStars = localStorage.getItem(game + "-" + cond[2] + "-stars");
				if(levelStars != 3) { ok = false; }
			}
		}
		//ok = true;
		return ok;
	}

	function toTitle(text) {
		return text.charAt(0).toUpperCase() + text.slice(1);
	}

	function getInstruction(conditions) {
		var instruction = "To earn this badge, you have to ";
		switch(conditions[0][1]) {
          case "finished":
            instruction += "finish at least " + conditions[0][2] + " levels";
            break;
          case "stars":
            instruction += "get at least " + conditions[0][2] + " stars";
            break;
          case "maxed":
            instruction += "get 3 stars on " + conditions[0][2] + " levels";
            break;
          case "level":
            instruction += "finish level " + toTitle(conditions[0][2]);
            break;
          case "maxlevel":
            instruction += "get 3 stars on level " + toTitle(conditions[0][2]);
            break;
          default:
            instruction += "";
        }

		instruction += " in the game " + toTitle(conditions[0][0]) + ".";
		return instruction;
	}

	function getUrl(condition) {
		var url = condition[0] + "/";

		if(condition[1] == "level" || condition[1] == "maxlevel") {
			url += condition[2];
		} else {
			url += "tilesets";
		}
		return url;
	}

	function Badge({name, title, conditions, category, onlyNew=false, noText=false}) {

		var hasBadge = localStorage.getItem("badge-" + name);
		if(hasBadge == "true") {hasBadge = true;}

		const [showVisible, setShowVisible] = useState(false);
        const handleCloseVisible = () => setShowVisible(false);
        const handleShowVisible = () => setShowVisible(true);

        const [showHidden, setShowHidden] = useState(false);
        const handleCloseHidden = () => setShowHidden(false);
        const handleShowHidden = () => setShowHidden(true);

		if(onlyNew) {
			if(newBadges[name] == 1) {
				return (
					<Card className="badgeCard newBadgeCard">
						<Card.Img className="badgeImage" variant="top" src={"/images/badges/" + name + ".png"}  />
						<Card.Text className="badgeText" > {title} </Card.Text>
						<br/>
					</Card>
				);
			} else {
				return (<></>);
			}
		} else {
			if(hasBadge) {

				return (
					<Card className="badgeCard">
					<Card.Img onClick={handleShowVisible} className="badgeImage" variant="top" src={"/images/badges/" + name + ".png"}  />
					{
						noText
						? <></>
						: <><Card.Text className="badgeText" > {title} </Card.Text><br/></>
					}

					<Modal show={showVisible} onHide={handleCloseVisible} >
					        <Modal.Header closeButton>
					          <Modal.Title>{title}</Modal.Title>
					        </Modal.Header>
					        <Modal.Body>

								<Row><Card.Img className="badgeImage badgeModal" variant="top" src={"/images/badges/" + name + ".png"}  /></Row>

								<span className="badgeModalText"> You've earned this badge! </span>
								<br/>

					        </Modal.Body>
					</Modal>

				</Card> );

			} else {
				return (
					<Card className="badgeCard">
					<Card.Img onClick={handleShowHidden} className="badgeImage imageHidden" variant="top" src={"/images/badges/" + name + ".png"}  />
					{
						noText
						? <></>
						: <><Card.Text className="badgeText" > {title} </Card.Text><br/></>
					}

				    <Modal show={showHidden} onHide={handleCloseHidden} >
					        <Modal.Header closeButton>
					          <Modal.Title>{title}</Modal.Title>
					        </Modal.Header>
					        <Modal.Body>

								<Row><Card.Img className="badgeImage badgeModal imageHidden" variant="top" src={"/images/badges/" + name + ".png"}  /></Row>


								<span className="badgeModalText"> { getInstruction(conditions) }</span>
								<br/><br/>
								<Button variant="primary small-btn" href={"/en/" + getUrl(conditions[0])} >Go to {toTitle(conditions[0][0])}</Button>

					        </Modal.Body>
					    </Modal>

				</Card>);

			}
		}
	}

	function Category({category, badges}) {

		if(category == "GENERAL") {
			return (<></>);
		}
		return (
			<>
				<h3>{category}</h3>
				<Row> { badges.map(bg => ( <Badge title={bg["title"]} name={bg["name"]} conditions={bg["conditions"]}
					category={category} key={category["title"]}  />)) }
				</Row>
				<br/>
			</>
		)
	}

	function CategorySmall({category, badges}) {

		return (
			<>
				<h1>Badges in {category}</h1>
				<Row> { badges.map(bg => ( <Badge title={bg["title"]} name={bg["name"]} conditions={bg["conditions"]}
					category={category} key={category["title"]} noText={true}  />)) }
				</Row>
			</>
		)
	}

	function CategoryNew({category, badges}) {

		return (
			<>
				<br/>

				<Row style={{"max-width": "400px", "margin": "auto"}}> { badges.map(bg => ( <Badge title={bg["title"]} name={bg["name"]}
					conditions={bg["conditions"]} category={category}  key={category["title"]} onlyNew={true}/>)) }
				</Row>
				<Button className="small-btn badges-btn" variant="primary" href="/badges" >See all your badges</Button>

			</>
		)
	}


	function getAllBadges() {

		var badges = badgesJson["badges"];
		const code = (
			<>
				{
					badges.map(category => ( <Category category={category["category"]} badges={category["badges"]} key={category["category"]} />))
				}
			</>
		);
		return code;

	}

	function countBadges(game) {
		var onlyNew = true;
		if(game == "all") {
			onlyNew = false;
		}
		var amount = 0;
		var badges = badgesJson["badges"];
		for(var i = 0; i < badges.length; i++) {
			var cat = badges[i]["badges"];
			for(var j = 0; j < cat.length; j++) {
				var name = cat[j]["name"];
				var conditions = cat[j]["conditions"];
				if(game == "all" || game == conditions[0][0]) {
					var hasBadge = localStorage.getItem("badge-" + name);

					if(hasBadge == "true") {
						if(!onlyNew) { amount+=1;}
					} else {
						var check = checkBadge(conditions);
	                    if(check) {
	                        amount += 1;
							localStorage.setItem("badge-" + name, "true");
							ReactGA.event({
			                    category: 'Badge',
			                    action: 'BadgeEarned',
			                    label: name
			                });
			                newBadges[name] = 1;
	                    }
					}
				}
			}
		}

		return amount;
	}


	if(badgesCounter == 0) {
		badgesCounter = countBadges(game);
	}
	const [showPopup, setShowPopup] = useState(false);
    const handleClosePopup = () => setShowPopup(false);
    const handleShowPopup = () => setShowPopup(true);


	if(game == "all") {
		const tilesetHtml =  (
        <div className="mainPart">
        <Helmet>
            <title>Unlimited Tiles - Badges</title>
            <meta name="description" content="Badges - can you collect them all? " />
            <link rel="canonical" href="https://www.unlimited-tiles.com/badges" />
            <meta property="og:url" content="https://www.unlimited-tiles.com/badges" />
            <meta property="og:image" content="https://www.unlimited-tiles.com/banner.png" />
            <meta property="og:title" content="Badges - can you collect them all? "/>
            <meta property="og:description" content="Find hidden words "/>
        </Helmet>

		<Container>
	        <h1>BADGES </h1>
	        <h2> <img src={"/images/badges/badge.png"}  /> {badgesCounter}/54 </h2>
			{getAllBadges()}
	    </Container>

        </div>
        );

        return tilesetHtml;

	} else if(game.includes("all-")) {
		game = game.replace("all-", "");

		return ( <CategorySmall category={badgesJson["badges"][gameId]["category"]} badges={badgesJson["badges"][gameId]["badges"]}
                         key={badgesJson["badges"][gameId]["category"]} />);

	} else {
		if(badgesCounter >= 1) {
			var text = "Hats off! " + badgesCounter + " new badges are yours!";
			if(badgesCounter == 1) { text = "Hats off! A new badge is yours!"; }

			return (
				<>

	            <Modal show={showPopup} onHide={handleClosePopup} >

					<Modal.Body>
					<h3>{text}</h3>
                    <CategoryNew category={badgesJson["badges"][gameId]["category"]} badges={badgesJson["badges"][gameId]["badges"]}
                                	                                                key={badgesJson["badges"][gameId]["category"]} />
					<br/>

					</Modal.Body>
				</Modal>
				<Button onClick={handleShowPopup} className="small-btn badges-btn" variant="primary" >See your new badges!</Button>
				</>
			);
		} else {
			return (<></>);
		}
	}

}




export default BadgesList;

